<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>

      <a-form-model-item label='示范名称' prop='exampleName' :required="true">
        <a-input v-model='form.exampleName' placeholder='请输入示范名称' :maxLength='32'/>
      </a-form-model-item>
<!--      <a-form-model-item label='封面' prop='exampleImg' :required="true">-->
<!--        <a-upload   listType='picture-card'-->
<!--                    class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload("exampleImg",info)'-->
<!--                    :customRequest='(info) => fileUploadRequest("exampleImg",info)' v-model="form.exampleImg">-->
<!--          <img v-if="form.exampleImg != null" :src='form.exampleImg' style='height:104px;max-width:300px' />-->
<!--          <div v-else>-->
<!--            <a-icon :type="loading ? 'loading' : 'plus'" />-->
<!--            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label='视频链接' prop='exampleRul' :required="true">
        <div v-if="form.exampleRul && form.exampleRul != null" style="display: inline-block;position:relative;">
          <img v-if="exampleImg != null " :src='exampleImg' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay()" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
        </div>
        <a-upload v-if="!form.exampleRul"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("exampleRul",info)'
                  :customRequest='(info) => videoUploadRequest("exampleRul",info)' v-model="form.exampleRul">
          <img v-if="form.exampleRul != null" :src='form.exampleRul' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.exampleRul && form.exampleRul">
          <a-button @click="restVideo">重置</a-button>
        </div>
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <VideoPlay ref="videoPlay"></VideoPlay>
  </a-drawer>
</template>

<script>
import { getExample, addExample, updateExample } from '@/api/music/example'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import VideoPlay from '@/components/VideoPlay.vue'

export default {
  name: 'CreateForm',
  props: {}
  ,
  components: { VideoPlay }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      // 表单参数
      exampleImg:null,
      form: {
        id: null,

        musicId: null,
        exampleName:null,

        exampleRul: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // title: [
        //   {
        //     required: true, message: '歌曲名称不能为空', trigger: 'blur'
        //   }
        // ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        musicId: null,
        exampleName:null,

        exampleRul: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(record) {
      this.reset()
      this.formType = 1
      this.open = true
      this.form.musicId = record.id
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getExample({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateExample(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addExample(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      // debugger
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      console.log('uploadfileInfo====前', fileInfo);
      if (!fileInfo.type.includes('video')) {
        this.$message.warning('请上传视频');
        return false;
      }
      if(fileInfo.size > 500*1024*1000){
        this.$message.warning("视频大小不能超过500M");
        return;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoUploadRequest(field,fileInfo) {
      console.info('uploadfileInfo上传', fileInfo);
      // debugger
      let that = this;
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.exampleImg = res[0].url + "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0";
          this.form[field] = res[0].url;

        },
      });
    },

    videoPlay: function () {
      let data = {
        coverImage: this.exampleImg,
        videoUrl: this.form.exampleRul
      };
      this.$refs.videoPlay.show(data);
    },
    // 视频鼠标悬浮时的样式
    enter: function ($event) {
      $event.currentTarget.className = "enter";
    },
    // 视频鼠标离开时的样式
    leave: function ($event) {
      $event.currentTarget.className = "leave";
    },
    restVideo() {

      this.form.exampleRul = null;
      this.exampleImg = null;
      this.loading = false
    },
  }
}
</script>
