<template>
  <page-header-wrapper>
    <a-card :bordered='false'>
      <!-- 条件搜索 -->
      <div class='table-page-search-wrapper'>
        <a-form layout='inline'>
          <a-row :gutter='48'>

            <a-col :md='6' :sm='24'>

              <a-form-item label='歌曲名称' prop='title'>
                <a-input v-model='queryParam.title' placeholder='请输入歌曲名称' @keyup.enter='handleQuery'
                         allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md='4' :sm='24'>

              <a-form-item label='上传人' prop='title'>
                <a-input v-model='queryParam.creatorName' placeholder='请输入上传人名称' @keyup.enter='handleQuery'
                         allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md='6' :sm='24'>

              <a-form-item label='排序选择' prop='title'>
                <api-select placeholder='请选择排序' v-model="queryParam.sortName" :option="sortOptions" />
              </a-form-item>
            </a-col>

            <a-col :md='!advanced && 8 || 24' :sm='24'>
              <span class='table-page-search-submitButtons'
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type='primary' @click='handleQuery'><a-icon type='search' />查询</a-button>
                <a-button style='margin-left: 8px' @click='resetQuery'><a-icon type='redo' />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class='table-operations'>
<!--        <a-button type='primary' @click='$refs.createForm.handleAdd()'-->
<!--                  v-hasPermi="['dynamic:music/library:add']">-->
<!--          <a-icon type='plus' />-->
<!--          新增-->
<!--        </a-button>-->
        <a-button type='primary' :disabled='multiple' @click='handleAddQuote'
                  v-hasPermi="['dynamic:music/library:add']">
          <a-icon type='plus' />
          添加引用
        </a-button>
        <a-button type='danger' :disabled='multiple' @click='handleDelete'
                  v-hasPermi="['dynamic:music/library:remove']">
          <a-icon type='delete' />
          删除
        </a-button>
        <a-button type='primary' @click='$refs.uploadBatch.handleAdd()' v-hasPermi="['dynamic:music/library:add']">
          <a-icon type='download' />
          上传
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync='tableSize'
          v-model='columns'
          :refresh-loading='loading'
          @refresh='getList' />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref='createForm'
        @ok='getList'
      />
      <!-- 曲调 -->
      <music-tune-form ref='musicForm' @ok='getList' />
      <!-- 示范 -->
      <music-example-form ref='musicExampleForm' @ok='getList' />
      <!-- 示范 -->
      <example-list ref='exampleList' @ok='getList' />
      <!-- 曲调 -->
      <music-skill-form ref='musicSkillForm' @ok='getList' />
      <skill-list ref='skillList' @ok='getList' />
      <!--   批量上传   -->
      <upload-batch ref='uploadBatch'
                    @ok='getList'/>
      <!-- 添加引用-->
      <create-quote ref='createQuote'
                    @ok='getList'/>
      <quote-list ref='quoteList'
                  @ok='getList'/>
      <!-- 数据展示 -->
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="0" tab="未引用歌曲" />
        <a-tab-pane key="1" tab="已引用歌曲" />
      </a-tabs>
      <a-table
        :loading='loading'
        :size='tableSize'
        rowKey='id'
        :columns='columns'
        :data-source='list'
        :row-selection='{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }'
        :pagination='false'
        :bordered='tableBordered'
      >
        <div slot="videoPic" slot-scope="text, record">
          <img style="width: 48px;height: 48px;" preview="视频封面" :src="record.videoPic"/>
        </div>
        <span slot='createTime' slot-scope='text, record'>
          {{ parseTime(record.createTime) }}
        </span>
        <span slot='operation' slot-scope='text, record'>
          <a-divider v-if='record.isQuote==1' type='vertical'  />
          <a v-if='record.isQuote==1' @click='$refs.quoteList.show(record)'
             v-hasPermi="['dynamic:music/library:list']">
            <a-icon type="unordered-list" />查看引用
          </a>
          <a-divider type='vertical' v-hasPermi="['dynamic:music/library:download']" />
          <a :href='record.videoUrl' download
             v-hasPermi="['dynamic:music/library:download']">
            <a-icon type="download" />下载
          </a>
          <a-divider type='vertical' v-hasPermi="['dynamic:music/library:edit']" />
          <a @click='$refs.createForm.handleUpdate(record, undefined)'
             v-hasPermi="['dynamic:music/library:edit']">
            <a-icon type='edit' />修改
          </a>

          <a-divider type="vertical"  />

           <a-dropdown   >
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
                <a-menu-item>
                   <a @click='$refs.musicForm.handleUpdate(record, undefined)'
                      v-hasPermi="['dynamic:music/library:edit']">曲调
                   </a>
                </a-menu-item>
               <a-menu-item>
                   <a @click='$refs.exampleList.show(record)'
                      v-hasPermi="['dynamic:music/library:edit']">示范
                   </a>
                </a-menu-item>
              <a-menu-item>
                   <a @click='$refs.musicExampleForm.handleAdd(record)'
                      v-hasPermi="['dynamic:music/library:edit']">添加示范
                   </a>
                </a-menu-item>
               <a-menu-item>
                   <a @click='$refs.skillList.show(record)'
                      v-hasPermi="['dynamic:music/library:edit']">技巧谱
                   </a>
              </a-menu-item>
              <a-menu-item>
                   <a @click='$refs.musicSkillForm.handleAdd(record)'
                      v-hasPermi="['dynamic:music/library:edit']">添加技巧谱
                   </a>
              </a-menu-item>
              <a-menu-item>
                    <a @click='handleDelete(record)' v-hasPermi="['dynamic:music/library:remove']">
<!--                      <a-icon type='delete' />-->
                      删除
                    </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class='ant-table-pagination'
        show-size-changer
        show-quick-jumper
        :current='queryParam.pageNum'
        :total='total'
        :page-size='queryParam.pageSize'
        :showTotal='total => `共 ${total} 条`'
        @showSizeChange='onShowSizeChange'
        @change='changeSize'
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageMusicLibrary, listMusicLibrary, delMusicLibrary,downloadRul } from '@/api/dynamic/musicLibrary'
import CreateForm from './modules/CreateForm'
import UploadBatch from './modules/UploadBatch.vue'
import { tableMixin } from '@/store/table-mixin'
import CreateQuote from './modules/CreateQuote.vue'
import QuoteList from './modules/QuoteList.vue'
import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import MusicTuneForm from '@/views/dynamic/musicLibrary/modules/MusicTuneForm.vue'
import MusicSkillForm from '@/views/dynamic/musicLibrary/modules/MusicSkillForm.vue'
import MusicExampleForm from '@/views/dynamic/musicLibrary/modules/MusicExampleForm.vue'
import ExampleList from '@/views/dynamic/musicLibrary/modules/ExampleList.vue'
import SkillList from '@/views/dynamic/musicLibrary/modules/SkillList.vue'
import { Form as curFile } from 'ant-design-vue'
export default {
  name: 'MusicLibrary',
  components: {
    ApiSelect,
    CreateForm,
    UploadBatch,
    CreateQuote,
    QuoteList,
    MusicTuneForm,MusicSkillForm,MusicExampleForm,ExampleList,SkillList
  },
  mixins: [tableMixin],
  props: {
    sortOptions: {
      type: Array,
      default() {
        return [{ id: 1, name: '上传时间(新-旧)' }, { id: 2, name: '上传时间(旧-新)' },
          { id: 3, name: '歌曲名称(A-Z)' }, { id: 4, name: '歌曲名称(Z-A)' },
          { id: 5, name: '歌曲时长(大-小)' }, { id: 6, name: '歌曲时长(小-大)' }];
      }
    }
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      activeKey: '0',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        title: null,
        creatorName:null,
        sortName:null,
        isQuote:0,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '歌曲名称',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '视频封面',
          dataIndex: 'videoPic',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'videoPic'}
        },
        {
          title: '上传人',
          dataIndex: 'creatorName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时长',
          dataIndex: 'videoTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上传时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {

    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab
    changeTab(index) {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      };
      this.activeKey = index;
      this.queryParam.isQuote = index;
      this.getList();
    },
    /** 查询动态曲库列表 */
    getList() {
      this.loading = true
      pageMusicLibrary(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        title: undefined,
        creatorName:undefined,
        sortName:null,
        isQuote: 0,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delMusicLibrary(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleAddQuote(row){
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      let data = []
      that.ids.map(id => data.push(id));
      that.onSelectChange([], [])
      this.$refs.createQuote.handleAdd(data);
    },
    handleDownload(record){
      this.loading = true
      return downloadRul({ fileUrl:record.videoUrl,fileName:record.title})
        .then(res => {
          console.log(res,"res")
          const url = window.URL.createObjectURL(res)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          let videoU = record.videoUrl;
          const fileName = record.title + videoU.substr(videoU.lastIndexOf("."),videoU.length)
          console.log(fileName,"file")
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.loading = false
        })

    }

  }
}
</script>
