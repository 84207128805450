<template>
  <a-modal title='歌曲引用' :visible="visible" width="800px"
           @cancel="handleCancel"
           @ok="handleOk" cancelText='关闭'  >
    <a-table
      ref="table"
      size="middle"
      bordered
      rowKey="id"
      :columns="columns"
      :dataSource="list"
      :pagination="false"
      :loading="loading">
              <span slot="action" slot-scope="text, record">
                <a-popconfirm title="确定解绑吗?" @confirm="() => deleteData(record)">
                 <a>解绑</a>
                </a-popconfirm>
              </span>
    </a-table>

  </a-modal>
</template>

<script>

import {getQuoteList,unbindingMusic} from '@/api/dynamic/musicLibrary'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'GraphCategory',
  components: {

  },
  mixins: [tableMixin],
  data() {
    return {
      musicId:null,
      visible:false,
      list: [],
      // 展开的行，受控属性
      expandedRowKeys: [],
      replaceFields: {
        id: "id",
        pid: "pid",
        children: "childList"
      },
      sortParams: {
        field: "sort",
        way: "ASC"
      },
      selectedRowKeys: [],
      selectedRows: [],

      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '#',
        //   dataIndex: '',
        //   key: 'rowIndex',
        //   width: 60,
        //   align: "center"
        // },
        {
          title: '一级分类',
          dataIndex: 'categoryPName',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '二级分类',
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '曲调',
          dataIndex: 'tuneName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {

  },
  computed: {},
  watch: {},
  methods: {
    show(record){
      this.visible = true;
      this.musicId = record.id;
      this.queryParam.musicId = record.id;
      console.log(record)
      this.getQuoteList();

    },
    /** 查询引用列表 */
    getQuoteList() {
      this.loading = true
      getQuoteList(this.queryParam).then(response => {
        this.list = response.data;
        this.total = response.data.total
        this.loading = false
      })
    },

    handleOk(){
      this.visible = false
      this.$emit('ok')
    },
    handleCancel(){
      this.visible = false
      this.$emit('ok')
    },
    deleteData(record){

      this.loading = true
      unbindingMusic(record).then(response => {
        if(response.success == true){
          this.$message.success(
            '解绑成功',
            3
          )
        }else{
          this.$message.warn(
            response.message,
            3
          )
        }

        this.loading = false
        this.getQuoteList();
      })
    }
  }
}
</script>
<style lang='less' scoped></style>