import request from '@/utils/request'


// 查询歌曲示范列表
export function listExample(query) {
    return request({
        url: '/music/music-example/list',
        method: 'get',
        params: query
    })
}

// 查询歌曲示范分页
export function pageExample(query) {
    return request({
        url: '/music/music-example/page',
        method: 'get',
        params: query
    })
}

// 查询歌曲示范详细
export function getExample(data) {
    return request({
        url: '/music/music-example/detail',
        method: 'get',
        params: data
    })
}

// 新增歌曲示范
export function addExample(data) {
    return request({
        url: '/music/music-example/add',
        method: 'post',
        data: data
    })
}

// 修改歌曲示范
export function updateExample(data) {
    return request({
        url: '/music/music-example/edit',
        method: 'post',
        data: data
    })
}

// 删除歌曲示范
export function delExample(data) {
    return request({
        url: '/music/music-example/delete',
        method: 'post',
        data: data
    })
}
